import { useState } from "react";
import {
  CognitoIdentityProviderClient,
  SignUpCommand,
  ConfirmSignUpCommand,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import hbaAcademyLogo from "../../images/HBA-acedemy-logo.png";
import LoginImage from "../../images/login-image.png"; // Assuming you have a signup image similar to login
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirect
import { getCurrentUser, fetchAuthSession, signIn } from "aws-amplify/auth";
import { generateClient } from "@aws-amplify/api";

const SignupForm = ({ setUser }) => {
  const client = generateClient();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userCategory, setUserCategory] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSignupComplete, setIsSignupComplete] = useState(false); // Track signup status (i.e., show OTP form)
  const navigate = useNavigate(); // To navigate to dashboard after login

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSymbol: false,
  });

  const validatePassword = (value) => {
    setPasswordValidation({
      minLength: value.length >= 8,
      hasLowerCase: /[a-z]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasNumber: /[0-9]/.test(value),
      hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const isPasswordValid = () => {
    return Object.values(passwordValidation).every(Boolean);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle sign-up logic
  const handleSignup = async (e) => {
    e.preventDefault();

    if (!isPasswordValid()) {
      setErrorMessage("Please ensure your password meets all requirements.");
      return;
    }
    setIsLoading(true);
    setErrorMessage("");

    const cognitoClient = new CognitoIdentityProviderClient({
      region: process.env.REACT_APP_COGNITO_REGION || "ap-southeast-1",
    });

    const signUpParams = {
      ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      Username: email,
      Password: password,
      UserAttributes: [
        { Name: "email", Value: email },
        { Name: "given_name", Value: firstName },
        { Name: "family_name", Value: lastName },
        { Name: "website", Value: window.location.origin },
      ],
    };

    try {
      const command = new SignUpCommand(signUpParams);
      await cognitoClient.send(command);
      setIsSignupComplete(true); // Proceed to OTP confirmation
    } catch (error) {
      console.error("Error signing up:", error);
      setErrorMessage("Sign-up failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle OTP confirmation logic
  const handleConfirmSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    const cognitoClient = new CognitoIdentityProviderClient({
      region: process.env.REACT_APP_COGNITO_REGION || "ap-southeast-1",
    });

    const confirmParams = {
      ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      Username: email,
      ConfirmationCode: confirmationCode,
    };

    try {
      const command = new ConfirmSignUpCommand(confirmParams);
      var temp = await cognitoClient.send(command);
      // Automatically log in after confirmation
      console.log("command", command);
      console.log("temp", temp);
      await createUser();
      // .then((x)=>navigate("/dashboard"));
    } catch (error) {
      console.error("Error confirming sign-up:", error);
      setErrorMessage(
        "Confirmation failed. Please check your code and try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  //Creating user
  const getDefaults = `query getDefaults {
    defaultUserType
    pages {
      id
      name
      features {
        id
        name
      }
    }
  }`;

  const mCreateUser = `mutation createUser($id: ID!, $email: AWSEmail, $firstName: String, $lastName: String, $userType: UserType, $company: CompanyInput, $businessName: String, $hasPortalAccess: Boolean){
    userCreate(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      userType: $userType
      company: $company
      businessName: $businessName
      hasPortalAccess: $hasPortalAccess
    ) {
      id
    }
  }`;

  const mCreateCompanyAccessType = `mutation createCompanyAccessType($companyId: String, $userType: [UserType], $access: [AccessInput]){
    companyAccessTypeCreate(
      companyId: $companyId
      userType: $userType
      access: $access
    ) {
      id
    }
  }`;

  const mCreateCompany = `mutation createCompany($name: String, $representative: RepresentativeInput){
    companyCreate(
      name: $name
      representative: $representative
    ) {
      id
      name
    }
  }`;

  const mCreateAppSubscription = `mutation createAppSubscription($companyId: ID, $registeredUserId: ID, $email: AWSEmail, $remainingTrialDays: Int, $tier: Tier, $registrationSourceCategory: String, $registrationSource: String, $registrationSourceWebsite: String) {
    appSubscriptionCreate(companyId: $companyId, registeredUserId: $registeredUserId, email: $email, remainingTrialDays: $remainingTrialDays, tier: $tier, registrationSourceCategory: $registrationSourceCategory, registrationSource: $registrationSource, registrationSourceWebsite: $registrationSourceWebsite){
      id
    }
  }`;

  const createUser = async () => {
    const { isSignedIn } = await signIn({
      username: email,
      password: password,
    });

    if (isSignedIn) {
      // Get user and session info
      const [user, session] = await Promise.all([
        getCurrentUser(),
        fetchAuthSession(),
      ]);

      localStorage.setItem("firstName", firstName);
      localStorage.setItem("firstName", lastName);

      // Store necessary information
      setUser(user.username);

      const cognitoUserInfo = await getCurrentUser();
      console.log("cognitoUserInfo", cognitoUserInfo);

      //STEP0: Get defaults
      const dafaults = await client.graphql({
        query: getDefaults,
      });

      const pages = dafaults.data.pages;
      const usertypes = dafaults.data.defaultUserType;

      const company = {
        name: `${firstName}'s Portal`,
        representative: {
          id: cognitoUserInfo?.userId,
          firstName: firstName,
          lastName: lastName,
        },
      };

      const userDetails = {
        id: cognitoUserInfo?.userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        company: company,
        userType: "OWNER",
        access: pages,
        businessName: "",
        hasPortalAccess: true,
      };

      //STEP1: Create company
      const companyResponse = client
        .graphql({
          query: mCreateCompany,
          variables: company,
        })
        .then((res) => {
          const companyId = res?.data?.companyCreate?.id;
          const newUser = { ...userDetails, company: res?.data?.companyCreate };

          if (res) {
            //STEP2: Create company Access. No dependency
            const requestCA = client.graphql({
              query: mCreateCompanyAccessType,
              variables: {
                companyId: companyId,
                access: pages,
                userType: usertypes,
              },
            });

            if (requestCA) console.log("Company Access Created", requestCA);

            //STEP3: Create user in user table
            const requestCU = client.graphql({
              query: mCreateUser,
              variables: newUser,
            });
            if (requestCU) console.log("User created", requestCU);

            //STEP4: Create Subscription
            const requestSub = client.graphql({
              query: mCreateAppSubscription,
              variables: {
                companyId: companyId,
                registeredUserId: cognitoUserInfo?.userId,
                email: email,
                remainingTrialDays: 14,
                tier: "TRIAL",
                registrationSourceCategory: "",
                registrationSource: "",
                registrationSourceWebsite: "",
              },
            });

            if (requestSub) console.log("Subscription Created");

            navigate("/dashboard");
          }
        });
    }
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left side: Signup/Confirmation Form */}
      <div className="lg:w-1/2 flex justify-center items-center bg-white p-8">
        <div className="max-w-lg w-full bg-white p-8">
          <img
            src={hbaAcademyLogo}
            alt="HBApp Logo"
            className="mb-2 w-44 -ml-4"
          />
          <h1 className="text-2xl font-semibold mb-4">
            {isSignupComplete
              ? "Verify Your Email"
              : "Master Your Homebuilding Project with Expert Guidance."}
          </h1>
          <p className="text-gray-500 mb-6">
            {isSignupComplete
              ? "Enter the confirmation code sent to your email."
              : "Navigate complexities, avoid costly mistakes, and build with confidence"}
          </p>

          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

          {!isSignupComplete ? (
            // Sign-up Form
            <form onSubmit={handleSignup}>
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your first name"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your last name"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password *
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Create a password"
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>

                {/* Password requirements checklist */}
                <div className="mt-2 space-y-2 text-sm">
                  <p
                    className={`flex items-center ${
                      passwordValidation.minLength
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                  >
                    {passwordValidation.minLength ? (
                      <svg
                        className="w-4 h-4 mr-2 text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <span className="mr-2">•</span>
                    )}
                    Minimum of 8 characters
                  </p>
                  <p
                    className={`flex items-center ${
                      passwordValidation.hasLowerCase
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                  >
                    {passwordValidation.hasLowerCase ? (
                      <svg
                        className="w-4 h-4 mr-2 text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <span className="mr-2">•</span>
                    )}
                    Must include at least 1 lowercase character (a-z)
                  </p>
                  <p
                    className={`flex items-center ${
                      passwordValidation.hasUpperCase
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                  >
                    {passwordValidation.hasUpperCase ? (
                      <svg
                        className="w-4 h-4 mr-2 text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <span className="mr-2">•</span>
                    )}
                    Must include at least 1 uppercase character (A-Z)
                  </p>
                  <p
                    className={`flex items-center ${
                      passwordValidation.hasNumber
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                  >
                    {passwordValidation.hasNumber ? (
                      <svg
                        className="w-4 h-4 mr-2 text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <span className="mr-2">•</span>
                    )}
                    Must include at least 1 numeral (0-9)
                  </p>
                  <p
                    className={`flex items-center ${
                      passwordValidation.hasSymbol
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                  >
                    {passwordValidation.hasSymbol ? (
                      <svg
                        className="w-4 h-4 mr-2 text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <span className="mr-2">•</span>
                    )}
                    Must include at least 1 symbol
                  </p>
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="user-type"
                  className="block text-sm font-medium text-gray-700"
                >
                  User Type
                </label>
                <select
                  id="user-type"
                  onChange={(e) => {
                    setUserCategory(e.target.value);
                    localStorage.setItem("userCategory", e.target.value);
                  }}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="HBA_HOMEOWNER">Homeowner</option>
                  <option value="OWNERBUILDER">Owner Builder</option>
                  <option value="HBA_ARCHITECT_SUPERINTENDENT">
                    Architect Superintendent
                  </option>
                </select>
              </div>

              <button
                type="submit"
                className={`mt-4 h-12 w-full py-2 px-4 border border-[#1E3A61] text-sm font-medium rounded-md text-white bg-[#1E3A61] ${
                  !isPasswordValid()
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-white hover:text-[#1E3A61]"
                }`}
                disabled={!isPasswordValid() || isLoading}
              >
                {isLoading ? "Signing Up..." : "START YOUR LEARNING JOURNEY"}
              </button>

              {/* <div className="mb-4">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password *
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Create a password"
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div> */}

              {/* <button
                type="submit"
                className="mt-4 h-12 w-full py-2 px-4 border border-[#1E3A61] text-sm font-medium rounded-md text-white bg-[#1E3A61] hover:bg-white hover:text-[#1E3A61] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button> */}
            </form>
          ) : (
            // OTP Confirmation Form
            <form onSubmit={handleConfirmSignup}>
              <div className="mb-4">
                <label
                  htmlFor="confirmationCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirmation Code *
                </label>
                <input
                  type="text"
                  id="confirmationCode"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter the code"
                  required
                />
              </div>

              <button
                type="submit"
                className="mt-4 h-12 w-full py-2 px-4 border border-[#1E3A61] text-sm font-medium rounded-md text-white bg-[#1E3A61] hover:bg-white hover:text-[#1E3A61] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? "Verifying..." : "Confirm Email"}
              </button>
            </form>
          )}

          <p className="mt-6 text-center text-sm text-gray-500">
            {isSignupComplete
              ? "Did not receive the code? "
              : "Already have an account? "}
            <a href="/" className="text-indigo-600">
              {isSignupComplete ? "Resend Code" : "Log in"}
            </a>
          </p>
        </div>
      </div>

      {/* Right side: Image */}
      <div
        className="lg:w-1/2 hidden lg:flex items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${LoginImage})` }}
      ></div>
    </div>
  );
};

export default SignupForm;
