import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import hbaAcademyLogo from '../../images/HBA-acedemy-logo.png';
import LoginImage from '../../images/login-image.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [stage, setStage] = useState('request'); // 'request' or 'reset'
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleRequestCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      await resetPassword({ username: email });
      setStage('reset');
    } catch (error) {
      console.error('Error requesting password reset:', error);
      switch (error.name) {
        case 'UserNotFoundException':
          setErrorMessage('No account found with this email address.');
          break;
        case 'LimitExceededException':
          setErrorMessage('Too many attempts. Please try again later.');
          break;
        default:
          setErrorMessage('Error requesting password reset. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword
      });
      navigate('/login', { 
        replace: true,
        state: { message: 'Password reset successful. Please log in with your new password.' }
      });
    } catch (error) {
      console.error('Error resetting password:', error);
      switch (error.name) {
        case 'CodeMismatchException':
          setErrorMessage('Invalid verification code.');
          break;
        case 'InvalidPasswordException':
          setErrorMessage('Password does not meet requirements.');
          break;
        case 'ExpiredCodeException':
          setErrorMessage('Verification code has expired. Please request a new one.');
          setStage('request');
          break;
        default:
          setErrorMessage('Error resetting password. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="w-full lg:w-1/2 flex justify-center items-center bg-white p-8">
        <div className="max-w-lg w-full bg-white p-8">
          <img
            src={hbaAcademyLogo}
            alt="HBApp Logo"
            className="mb-2 w-44 -ml-4"
          />
          <h1 className="text-2xl font-semibold mb-4">
            Reset Your Password
          </h1>
          <p className="text-gray-500 mb-6">
            {stage === 'request' 
              ? 'Enter your email address to receive a password reset code'
              : 'Enter the verification code sent to your email and your new password'
            }
          </p>

          {errorMessage && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
              <p className="text-sm text-red-700">{errorMessage}</p>
            </div>
          )}

          {stage === 'request' ? (
            <form onSubmit={handleRequestCode}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your email address"
                  required
                />
              </div>

              <button
                type="submit"
                className="mt-4 h-12 w-full py-2 px-4 border border-[#1E3A61] text-sm font-medium rounded-md text-white bg-[#1E3A61] hover:bg-white hover:text-[#1E3A61] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send Reset Code'}
              </button>
            </form>
          ) : (
            <form onSubmit={handleResetPassword}>
              <div className="mb-4">
                <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                  Verification Code
                </label>
                <input
                  type="text"
                  id="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter verification code"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter new password"
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="mt-4 h-12 w-full py-2 px-4 border border-[#1E3A61] text-sm font-medium rounded-md text-white bg-[#1E3A61] hover:bg-white hover:text-[#1E3A61] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? 'Resetting...' : 'Reset Password'}
              </button>
            </form>
          )}

          <div className="mt-6 text-center">
            <button
              onClick={() => navigate('/login')}
              className="text-sm text-indigo-600 hover:text-indigo-500"
            >
              Back to Login
            </button>
          </div>
        </div>
      </div>
      
      {/* Right side: Image */}
      <div className="hidden lg:block lg:w-1/2 bg-cover bg-center" style={{ backgroundImage: `url(${LoginImage})` }} />
    </div>
  );
};

export default ForgotPassword;