const awsmobile = {
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

      // Authentication mechanisms
      loginWith: {
        email: true,
        phone: false,
        username: false,
      },

      // Verification settings
      signUpVerificationMethod: "code",
      verificationMechanisms: ["EMAIL"],

      // Password settings
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
        requireUppercase: true,
      },
    },
  },

  // API (AppSync) Configuration
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      region: process.env.REACT_APP_REGION,
      defaultAuthMode: "userPool",
    },
  },
};

export default awsmobile;
