// src/components/MainContent.js
import React, { useEffect, useRef, useState } from 'react';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import './maincontent.css'
import module1 from "../../images/module1-cert.png";
import module2 from "../../images/module2-cert.png";
import module3 from "../../images/module3-cert.png";
import { useParams, useNavigate } from 'react-router-dom';
import { generateClient } from '@aws-amplify/api';
import { getCurrentUser, fetchAuthSession } from '@aws-amplify/auth';

const MainContent = ({ user, setSelectedTile, selectedTile, setShowQuiz, showQuiz, currentModule, fetchCompletedLessons, lessonList }) => {
  const client = generateClient();
  const { ModuleNum } = useParams();
  const navigate = useNavigate();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const iframeRef = useRef(null);
  const canvasRef = useRef(null);
  const [completedLessonsList, setCompletedLessonsList] = useState([]);
  const [isAutoplay, setIsAutoPlay] = useState(false);

  // Get current lesson from module data
  const currentLesson = lessonList?.find(lesson => lesson.title === selectedTile);

  //Checker if quiz is done
  // useEffect(() => {
  //   const handleTypeformMessage = (event) => {
  //     if (event.data && event.data.type === 'form-submit') {
  //       handleNextLesson(currentLesson?.title, currentLesson?.id);
  //       setQuizCompleted(true);
  //     }
  //   };

  //   window.addEventListener('message', handleTypeformMessage);
  //   return () => {
  //     window.removeEventListener('message', handleTypeformMessage);
  //   };
  // }, []);

  //Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  //triggered when next lesson is clicked
  const handleNextLesson = async (currentLessonTitle, currentLessonId) => {
    const userId = localStorage.getItem("userId");
    setQuizCompleted(false);
  
    if(userId){
    try {
      // Find current lesson in module data
      const currentLesson = currentModule.lessons.find(
        lesson => lesson.title === currentLessonTitle
      );
  
      if (!currentLesson) {
        console.error('Lesson not found');
        return;
      }
  
      // Handle navigation
      const currentIndex = currentModule.lessons.findIndex(
        lesson => lesson.title === currentLessonTitle
      );
  
      if (currentIndex < currentModule.lessons.length - 1) {
        const nextLesson = currentModule.lessons[currentIndex + 1];
        setShowQuiz(false);
        setSelectedTile(nextLesson.title);
      } else {
        setShowQuiz(false);
        setSelectedTile("Course Completion");
        handleNextModule(currentModule?.id)
      }

      await createCompletedLesson(currentLessonId, userId)
  
    } catch (error) {
      console.error('Error handling lesson completion:', error);
    }
    }
  };

  const COMPLETE_LESSON_MUTATION = `
    mutation createCompletedLessons($lessonId: ID!, $userId: ID!, $dateCompleted: AWSDateTime!) {
      completedLessonCreate(
        input: {
          lessonId: $lessonId, 
          userId: $userId, 
          dateCompleted: $dateCompleted
        }
      ) {
        id
      }
    }
  `;

  //function for creating completed lesson entry
  const createCompletedLesson = async (currentLessonId, userId) => {
    try {
      console.log("useridddd", userId)
      console.log("currentLessonId>>>", currentLessonId)
      const result = await client.graphql({
        query: COMPLETE_LESSON_MUTATION,
        variables: {
            lessonId: currentLessonId,
            userId: userId,
            dateCompleted: new Date().toISOString()
        }
      });

      console.log('Lesson completion saved:', result);
      
      // Refresh the completed lessons list
      await fetchCompletedLessons();
      
      return result.data.completedLessonCreate.id;
    } catch (error) {
      console.error('Error creating completed lesson:', error);
      return null;
    }
  };

  const COMPLETE_MODULE_MUTATION = `
    mutation createCompletedModules($moduleId: ID!, $userId: ID!, $dateCompleted: AWSDateTime!) {
      completedModuleCreate(
        input: {
          moduleId: $moduleId, 
          userId: $userId, 
          dateCompleted: $dateCompleted
        }
      ) {
        id
      }
    }
    `;

  //Triggered when all lessons are done
  const handleNextModule = async (moduleId) => {
    try {
      const userId = localStorage.getItem("userId");

      console.log("useridddd", userId)
      console.log("currentmoduleId>>>", moduleId)
      const result = await client.graphql({
        query: COMPLETE_MODULE_MUTATION,
        variables: {
            moduleId: moduleId,
            userId: userId,
            dateCompleted: new Date().toISOString()
        }
      });

      console.log('Module completion saved:', result);
      
      return result;
    } catch (error) {
      console.error('Error creating completed module:', error);
      return null;
    }
  }

  const capitalize = (str) => {
    if (!str) return ''; // Handle null or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

const downloadCertificate = (moduleNum) => {
  var userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // Check if userDetails exists
  if (!userDetails) {
    console.error('User details not available');
    return;
  }

  // Check if canvas ref is available
  if (!canvasRef.current) {
    console.error('Canvas element not found');
    return;
  }

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');

  const image = new Image();
  var imageSrc = moduleNum === 1 ? module1 : moduleNum === 2 ? module2 : module3;
  console.log("imagesrc", imageSrc)
  image.src = imageSrc;

  image.onload = () => {
    try {
      // Set canvas dimensions to the image size
      canvas.width = image.width;
      canvas.height = image.height;
      
      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0);

      // Set larger font properties for the text
      ctx.font = 'bold 300px serif';
      ctx.fillStyle = '#000000';

      const fullName = `${capitalize(userDetails?.firstName || '')} ${capitalize(userDetails?.lastName || '')}`.trim();
      
      if (!fullName) {
        console.error('User name not available');
        return;
      }

      // Measure the width of the name
      const textWidth = ctx.measureText(fullName).width;

      // Calculate the X position to center the text
      const xPosition = (canvas.width - textWidth) / 2;
      const fontSize = 72;
      const yPosition = (canvas.height / 2 + fontSize / 2) - 100; 
  
      // Draw the name on the certificate
      ctx.fillText(fullName, xPosition, yPosition);

      const imgData = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = imgData;
      link.download = `Homebuilding-certificate-${moduleNum}.png`;

      // Trigger the download
      link.click();
    } catch (error) {
      console.error('Error generating certificate:', error);
    }
  };

  image.onerror = () => {
    console.error('Error loading certificate image');
  };
};

 //Checker if quiz is done
 useEffect(() => {
  const handleTypeformMessage = (event) => {
    if (event.data && event.data.type === 'form-submit') {
      console.log("submitted")
      console.log(">>", currentLesson?.title, currentLesson?.id)
      handleNextLesson(currentLesson?.title, currentLesson?.id);
      setQuizCompleted(true);
    }
  };

  window.addEventListener('message', handleTypeformMessage);
  return () => {
    window.removeEventListener('message', handleTypeformMessage);
  };
}, [currentLesson?.title, currentLesson?.id]);
  

  const renderContent = () => {

    const isStandardTile = ["Course Completion", "Recommended Learning"].includes(selectedTile);

    // const handleSubmit = () => {
    //   setQuizCompleted(true)
    //   handleNextLesson(currentLesson.title, currentLesson.id);
    //   console.log("Submitted")

    // }

    //KJMF RECORD ACTIVITY IF DONNE WITH COURSE
    const mRecordHbaAcademy = `
    mutation createActivity(
      $companyId: ID,
      $activity: String,
      $appModule: AppModules,
      $field: String,
      $current: String,
      $previous: String,
      $rowId: String
    ) {
      activityCreate(
        activity: $activity
        companyId: $companyId
        appModule: $appModule
        previous: $previous
        field: $field
        current: $current
        rowId: $rowId
      ) {
        id
      }
    }
  `;

    const addActivity = (moduleName)=> {
      // Log Activity
      const params = {
        query: mRecordHbaAcademy,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: `Completed Course: ${moduleName}`,
          userId: localStorage.getItem("userId"),
          appModule: "HBAACADEMY",
        },
      };

      const addActivity = client.graphql(params).then((result) => {
        console.log(`Completed Course: ${moduleName}`, result);
      });
    }

    return (
      <div>
        {showQuiz ? (
          <div className="w-full h-[620px]">
            <div className="w-full h-full">
              <iframe
                      id="typeform-full"
                      title="Typeform Quiz"
                      src={currentLesson?.quiz}
                      width="100%"
                      height="600px"
                      frameBorder="0"
                      allow="camera; microphone; autoplay; encrypted-media;"
                      className="rounded"
                      // onSubmit={handleSubmit}      
                    ></iframe>
            </div>

            <div className="flex justify-between mt-4 py-8">
              <button
                onClick={() => setShowQuiz(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Back to Video
              </button>
              
              <button
                onClick={() => handleNextLesson(currentLesson?.title, currentLesson?.id)}
                className={"border border-gray-300 rounded px-4 py-2 hover:bg-gray-100"
                //   `px-4 py-2 ${
                //   quizCompleted ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-600'
                // } text-white rounded`
                }
              >
                Next Lesson
              </button>
            </div>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-4">
              {!isStandardTile ? currentLesson?.title : ""}
            </h2>

            {currentLesson?.video && (
              <>
                <iframe
                  className="w-full h-[calc(60vh)] rounded-lg mb-6"
                  src={`https://www.youtube.com/embed/${currentLesson.video}?autoplay=1`}
                  title="Video Presentation"
                  frameBorder="0"
                  allow=
                  {isAutoplay === true ?
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" :
                    "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  }
                  allowFullScreen
                />

                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center space-x-2">
                    {isAutoplay === true ? (
                      <FaToggleOn
                        className="text-blue-600 text-2xl cursor-pointer"
                        onClick={()=>{setIsAutoPlay(false);localStorage.setItem("isAutoplay", "false")}}
                      />
                    ) : (
                      <FaToggleOff
                        className="text-gray-400 text-2xl cursor-pointer"
                        onClick={()=>{setIsAutoPlay(true);localStorage.setItem("isAutoplay", "true")}}
                      />
                    )}
                    <span className="text-gray-700">Auto Play</span>
                  </div>

                  <button 
                    className="border border-gray-300 rounded px-4 py-2 hover:bg-gray-100"
                    onClick={() => {
                      if (currentLesson.title === "Module Overview") {
                        handleNextLesson(currentLesson.title, currentLesson.id);
                      } else {
                        setShowQuiz(true);
                        scrollToTop();
                      }
                    }}
                  >
                    {currentLesson.title === "Module Overview" ? "Next" : "Take Quiz"}
                  </button>
                </div>
              </>
            )}

            {currentLesson?.lessonOverview && (
              <div className="mb-4">
                <h3 className="font-semibold">
                  {currentLesson.title === "Module Overview" ? "Module Overview" : "Lesson Overview"}
                </h3>
                <p className="mb-4 font-sm text-gray-500 mt-3">{currentLesson.lessonOverview}</p>
              </div>
            )}

            {currentLesson?.keyConcepts && (
              <div className="mb-4">
                <h3 className="font-semibold">Key Concepts</h3>
                <ul className="list-disc list-inside font-sm text-gray-500 mt-3 ml-4">
                  {Array.isArray(currentLesson.keyConcepts) ? (
                    currentLesson.keyConcepts.map((concept, index) => (
                      <li key={index} className="mb-1">{concept}</li>
                    ))
                  ) : (
                    // If it's a single string, split by periods and filter empty items
                    currentLesson.keyConcepts.split('.')
                      .filter(item => item.trim())
                      .map((concept, index) => (
                        <li key={index} className="mb-1">{concept.trim()}</li>
                      ))
                  )}
                </ul>
              </div>
            )}


            {currentLesson?.learningObjectives && (
              <div className="mb-4">
                <h3 className="font-semibold">Learning Objectives</h3>
                <ul className="list-disc list-inside font-sm text-gray-500 mt-3 ml-4">
                  {Array.isArray(currentLesson.learningObjectives) ? (
                    currentLesson.learningObjectives.map((objective, index) => (
                      <li key={index} className="mb-1">{objective}</li>
                    ))
                  ) : (
                    // If it's a single string, split by periods and filter empty items
                    currentLesson.learningObjectives.split('.')
                      .filter(item => item.trim())
                      .map((objective, index) => (
                        <li key={index} className="mb-1">{objective.trim()}</li>
                      ))
                  )}
                </ul>
              </div>
            )}

            {selectedTile === "Course Completion" && (
              <div className="w-full max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
                <h2 className="text-2xl font-bold mb-4 text-center text-gray-900">
                  Congratulations on successfully completing the
                </h2>
                <h1 className="text-3xl font-bold mb-6 text-center text-gray-900">
                  {currentModule.title} Course!
                </h1>

                <div className="mb-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    Ready to put your knowledge into action? Here's how HBApp can help:
                  </h3>
                  <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li>Track your project's progress using our Task List and Site Diary features</li>
                    <li>Manage contracts and variations with our dedicated tiles</li>
                    <li>Monitor your budget and expenses with our Budget and Invoices tools</li>
                    <li>Ensure compliance with our Building Permit feature</li>
                  </ul>
                </div>

                <div className="flex justify-between mt-8">
                  <button
                    className="px-4 py-2 bg-white-100 border border-black text-gray-700 rounded hover:bg-gray-200"
                    onClick={() => window.open('https://homebuilding.app', '_blank')}
                  >
                    Apply this in HBApp Now
                  </button>
                  <button
                    className="px-4 py-2 bg-green-800 text-white rounded hover:bg-green-600"
                    onClick={() => {handleNextModule(currentModule?.id);setSelectedTile("Recommended Learning");addActivity(currentModule?.title)}}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {selectedTile === "Recommended Learning" && (
              <div className="bg-white p-8 rounded-lg shadow-md max-w-2xl mx-auto my-12">
                <h2 className="text-lg font-semibold text-gray-600">Recommended Learning</h2>
                <h1 className="text-3xl font-bold text-gray-900 my-4">
                  Continue your learning journey!
                </h1>
                <p className="text-lg font-semibold text-gray-700 mb-4">
                  Explore our other courses to further enhance your home-building knowledge and skills.
                </p>
                <p className="text-gray-600 mb-8">
                  Whether you're interested in sustainable practices, renovation techniques, or advanced 
                  project management, our comprehensive modules will equip you with the expertise to 
                  tackle any home-building challenge with confidence.
                </p>
                <button 
                  className="px-4 py-2 bg-green-800 text-white rounded hover:bg-green-600"
                  onClick={() => navigate('/dashboard')}
                >
                  Explore Other Module Courses
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-full px-8 py-4">
      {renderContent()}
    </div>
  );
};

export default MainContent;