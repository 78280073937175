// src/components/learn-dashboard/Learn.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import Banner from '../banner/banner';
import Sidebar from '../sidebar/sidebar';
import MainContent from '../maincontent/maincontent';
import { generateClient } from '@aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

function Learn({ user, signOut }) {
  const [selectedTile, setSelectedTile] = useState('Module Overview');
  const [showQuiz, setShowQuiz] = useState(false);
  const [moduleList, setModuleList] = useState(null); //Contains all the modules
  const [lessonList, setLessonList] = useState([]); //Contains all the lessons under one module
  const [currentModule, setCurrentModule] = useState(null); //Contains the one module selected; Matching URL param
  const [isLoading, setIsLoading] = useState(true);
  const { ModuleNum } = useParams();
  const navigate = useNavigate();
  const [completedLessons, setCompletedLessons] = useState(null); //Array of ids [id1, id2, ...]
  const [completedModules, setCompletedModules] = useState(null); //Array of ids [id1, id2, ...]
  const userId = localStorage.getItem("userId");

  const client = generateClient();

  //1. Get completed Modules
  useEffect(() => {
    if(completedModules === null && userId){
      fetchCompletedModules(userId);
    }
  }, []);

  //2. Get completed module list query
  const GET_COMPLETED_MODULES_QUERY = `
  query getCompletedModuleByUser($userId: String!) {
    completedModules(userId: $userId) {
      modules {
        description
        featuredImageUrl
        id
        moduleNumber
        title
        lessons {
          id
          keyConcepts
          learningObjectives
          lessonOverview
          quiz
          title
          video
          knowledgeCheck {
            format
            instructions
            nextSteps
          }
        }
      }
    }
  }
  `;

  //3. Get completed module list
  const fetchCompletedModules = async (userId) => {
    try {
      console.log("UserID", userId)
      const result = await client.graphql({
        query: GET_COMPLETED_MODULES_QUERY,
        variables: { userId: userId }
      });

      console.log('Completed Module List:', result);
      
      if(result?.data?.completedModules?.modules){
        const completedModules = result?.data?.completedModules?.modules;
        var completedModulesId = [];
        completedModules.map(x=> completedModulesId ? completedModulesId = [...completedModulesId, x.id] : x)
        console.log("completedModulesId", completedModulesId)

        setCompletedModules(completedModulesId);
      }else{
        setCompletedModules([]);
      }

      
    } catch (error) {
      console.log("Error fetching modules", error)
    }
  };


  //GET MODULE LIST
  const GET_MODULES_QUERY = `query listModules {
    modules {
      id
      title
      moduleNumber
      description
      featuredImageUrl
      lessons {
        id
        title
        video
        quiz
        learningObjectives
        lessonOverview
        keyConcepts
        knowledgeCheck {
          format
          instructions
          nextSteps
        }
      }
    }
  }`;

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const result = await client.graphql({
          query: GET_MODULES_QUERY
        });

        // Sort modules by moduleNumber
        const sortedModules = result.data.modules.sort((a, b) => 
          parseInt(a.moduleNumber) - parseInt(b.moduleNumber)
        );
        
        setModuleList(sortedModules);

        // Find the module that matches the URL parameter (ModuleNum - 1 for zero-based index)
        const moduleIndex = parseInt(ModuleNum) - 1;
        const selectedModule = sortedModules[moduleIndex];

        if (selectedModule) {
          console.log('Selected Module:', selectedModule);
          console.log('Lessons under Module:', selectedModule?.lessons);
          setCurrentModule(selectedModule);
          setLessonList(selectedModule?.lessons)
        } else {
          console.error('Module not found');
          navigate('/dashboard');
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error Loading Modules:', error);
      } 
    };

    if(moduleList === null){
      fetchModules();
    }
    
  }, [moduleList]);

  //FETCH COMPLETED LESSONS
  const GET_COMPLETED_LESSONS =`
  query GetCompletedLessons($userId: String!) {
    completedLesson(userId: $userId) {
      id
      userId
      lessons {
        id
        title
        keyConcepts
        learningObjectives
        lessonOverview
        quiz
        video
        knowledgeCheck {
          format
          instructions
          nextSteps
        }
      }
      dateCompleted
    }
  }
`;

  const fetchCompletedLessons = async () => {
    const userId = localStorage.getItem("userId");
    if (!userId) return;

    try {
      console.log("Making query with userId:", userId); // Debug log

      const result = await client.graphql({
        query: GET_COMPLETED_LESSONS,
        variables: { 
          userId: userId  // Make sure variable name matches query parameter
        }
      }).then((res)=>{
        console.log("CompletedLessons:", res);
        var tempCompLesson = res?.data?.completedLesson?.lessons;
        var compLessonIds = [];

        console.log("Comp Lesson Ids????", compLessonIds)
        tempCompLesson.map(x=> compLessonIds = [...compLessonIds, x.id]);
        console.log("Comp Lesson Ids", compLessonIds)
        setCompletedLessons(compLessonIds)
        setIsLoading(false)
      });        
    } catch (error) {
      console.error('Error Loading Completed Lessons:', error);
    } 
  };

  useEffect(() => {
    if(completedLessons === null){
      fetchCompletedLessons();
    }
    
  }, [completedLessons]);


  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar user={user} signOut={signOut} />
        <div className="flex-1 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#1E3A61]"></div>
        </div>
      </div>
    );
  }

  if (!currentModule) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar user={user} signOut={signOut} />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-red-600">Module not found</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar user={user} signOut={signOut} />
      
      <Banner 
        title={currentModule.title}
        description={currentModule.description}
      />
      
      <div className="flex flex-1">
        <Sidebar 
          currentModule={currentModule}
          lessonList={lessonList}
          selectedTile={selectedTile}
          setSelectedTile={setSelectedTile}
          setShowQuiz={setShowQuiz}
          showQuiz={showQuiz}
          completedLessons={completedLessons}
          completedModules={completedModules}
        />
        
        <main className="flex-1 ml-64 mt-36 p-6 overflow-y-auto">
          <MainContent 
            currentModule={currentModule}
            selectedTile={selectedTile}
            setSelectedTile={setSelectedTile}
            showQuiz={showQuiz}
            setShowQuiz={setShowQuiz}
            user={user}
            moduleList={moduleList}
            currentModuleIndex={parseInt(ModuleNum) - 1}
            fetchCompletedLessons={fetchCompletedLessons}
            lessonList={lessonList}
          />
        </main>
      </div>
    </div>
  );
}

export default Learn;