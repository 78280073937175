import React, { useState } from 'react';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { FaCircle, FaCheckCircle } from 'react-icons/fa';
import { MdOutlineAccessTime } from 'react-icons/md';

const Sidebar = ({ selectedTile, setSelectedTile, setShowQuiz, showQuiz, currentModule, lessonList, completedLessons, completedModules }) => {
  const [openSection, setOpenSection] = useState("Module Overview");

  const standardTiles = [
    { id: 'course-completion', title: 'Course Completion' },
    { id: 'recommended-learning', title: 'Recommended Learning' }
  ];

  const toggleSection = (lessonTitle) => {
    setOpenSection(openSection === lessonTitle ? null : lessonTitle);
    setSelectedTile(lessonTitle);
    console.log(lessonTitle)
    setShowQuiz(false);
  };

  const getFirstPhrase = (overview) => {
    if (!overview) return "";
    const firstSentence = overview.split('.')[0];
    return firstSentence + '.';
  };

  const isStandardTile = (title) => {
    return standardTiles.some(tile => tile.title === title);
  };

  const isLessonClickable = (currentIndex, item) => {
    // If it's a standard tile, handle differently
    if (isStandardTile(item.title)) {
      return completedModules?.includes(currentModule?.id);
    }
  
    // Get only the lesson items (excluding standard tiles)
    const lessonsOnly = allTiles.filter(tile => !isStandardTile(tile.title));
    const lessonIndex = lessonsOnly.findIndex(lesson => lesson.id === item.id);
  
    // If it's the first lesson, it's always clickable
    if (lessonIndex === 0) return true;
  
    // If this lesson is already completed, it's clickable
    if (completedLessons?.includes(item.id)) return true;
  
    // If the previous lesson is completed, this lesson should be clickable
    const previousLesson = lessonsOnly[lessonIndex - 1];
    if (previousLesson && completedLessons?.includes(previousLesson.id)) return true;
  
    return false;
  };

  if (!lessonList) {
    return (
      <aside className="w-64 bg-white border-r border-gray-200 fixed top-[160px] bottom-0 overflow-y-auto">
        <div className="p-4">Loading lessons...</div>
      </aside>
    );
  }

  const allTiles = [...lessonList, ...standardTiles];

  return (
    <aside className="w-64 bg-white border-r border-gray-200 fixed top-[160px] bottom-0 overflow-y-auto">
      <nav className="px-4 py-6 space-y-2 -mt-5">
        {allTiles.map((item, index) => (
          <div key={item.id} className="border-b border-gray-200">
            <button
              className={`flex items-start w-full py-3 text-left text-gray-900 hover:bg-gray-100 rounded focus:outline-none ${
                !isLessonClickable(index, item) ? 'opacity-70 cursor-not-allowed' : ''
              }`}
              onClick={() => {
                if (isLessonClickable(index, item)) {
                  toggleSection(item.title);
                } else {
                  alert("Finish Previous Lesson first.");
                }
              }}
            >
              {/* Rest of the button content remains the same */}
              <div className="w-10 flex justify-center items-center">
                {!isStandardTile(item.title) && (
                  selectedTile === item.title ? (
                    <IoIosArrowDown className="text-blue-700" />
                  ) : (
                    <IoIosArrowForward className="text-blue-700" />
                  )
                )}
              </div>
              <div className="flex flex-col w-full">
                <span className="font-semibold flex text-sm">
                  {item.title}
                  {!isStandardTile(item.title) && completedLessons?.includes(item.id) && (
                    <div className='w-5'>
                      <FaCheckCircle size={14} className='ml-2 mt-1 text-green-500' />
                    </div>
                  )}
                </span>
                {!isStandardTile(item.title) && (
                  <p className="text-sm text-gray-400 flex items-center">
                    <span className="inline-block mr-1">1 Video</span> | 
                    <span className="inline-block ml-1 flex">
                      <MdOutlineAccessTime size={16} className="mr-1 mt-[2px]" />
                      Total: 3 minutes
                    </span>
                  </p>
                )}
              </div>
            </button>

            {/* Rest of the component remains the same */}
            {!isStandardTile(item.title) && selectedTile === item.title && (
              <div className="flex flex-col pb-4 w-5/6 ml-8">
                <div className="relative">
                  <div className="flex relative mb-4">
                    <div className="relative flex flex-col items-center">
                      <div className="w-3 h-3 rounded-full bg-blue-600 z-20 mt-1" />
                      <div className="absolute w-0.5 bg-blue-600 top-4 h-full" />
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-black text-sm">Lesson Overview</p>
                      <p className="text-gray-500 text-xs mt-1">
                        {getFirstPhrase(item.lessonOverview)}
                      </p>
                    </div>
                  </div>

                  <div className={item.title === "Module Overview" ? "hidden" : "flex relative mb-4 -mt-2"}>
                    <div className="relative flex flex-col items-center">
                      <div className={`${showQuiz ? 'bg-blue-600' : 'bg-gray-300'} w-3 h-3 rounded-full bg-blue-600 z-20 mt-1`} />
                      <div className={`${showQuiz ? 'bg-blue-600' : 'bg-gray-300'} absolute w-0.5 top-4 h-full`} />
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-black text-sm">Knowledge Check</p>
                      <p className="text-gray-500 text-xs mt-1">
                        Test your knowledge and understanding of this lesson by taking the short quiz before the next lesson.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;