import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { Helmet } from "react-helmet";

// Configure Amplify
Amplify.configure({
  Auth: config.Auth,
  API: config.API,
});

// Debug logging
// console.log("Amplify Configuration:", {
//   Auth: {
//     ...config.Auth,
//     Cognito: {
//       ...config.Auth.Cognito,
//       // Don't log sensitive values
//       userPoolId: "***",
//       userPoolClientId: "***",
//       identityPoolId: "***",
//     },
//   },
//   API: config.API,
// });

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>{process.env.REACT_APP_HEADER_TITLE}</title>
      <meta
        name="og:description"
        content={process.env.REACT_APP_HEADER_DESCRIPTION}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content={process.env.REACT_APP_HEADER_LOGO}
        data-react-helmet="true"
      ></meta>
      <meta
        name="msapplication-TileImage"
        content={process.env.REACT_APP_HEADER_FAVICON}
        data-react-helmet="true"
      ></meta>
      <link
        rel="icon"
        href={process.env.REACT_APP_HEADER_FAVICON}
        sizes="16x16"
        data-react-helmet="true"
      />
      <link
        rel="icon"
        href={process.env.REACT_APP_HEADER_FAVICON}
        sizes="32x32"
        data-react-helmet="true"
      />
      <link
        rel="icon"
        href={process.env.REACT_APP_HEADER_FAVICON}
        sizes="192x192"
        data-react-helmet="true"
      />
      <link
        rel="apple-touch-icon-precomposed"
        href={process.env.REACT_APP_HEADER_FAVICON}
        data-react-helmet="true"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={process.env.REACT_APP_HEADER_FAVICON}
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={process.env.REACT_APP_HEADER_FAVICON}
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={process.env.REACT_APP_HEADER_FAVICON}
      ></link>
    </Helmet>

<App />
</React.StrictMode>
, document.getElementById("root"));
