import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/navbar';
import { useNavigate } from 'react-router-dom';
import { generateClient } from '@aws-amplify/api';
import { AiFillInfoCircle } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { MdCheck } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import { updatePassword } from 'aws-amplify/auth';
import ToastNotification from "../toast-notification/"; 
import m1 from "../../images/thumbnail-1.png";
import m2 from "../../images/thumbnail-2.png";
import m3 from "../../images/thumbnail-3.png";
import m4 from "../../images/thumbnail-1.png";
import m5 from "../../images/thumbnail-1.png";
import m6 from "../../images/thumbnail-1.png";
import hbaLogo from "../../images/HBA-acedemy-logo.png";
import module1 from "../../images/module1-cert.png";
import module2 from "../../images/module2-cert.png";
import module3 from "../../images/module3-cert.png";

const Profile = ({ user, signOut }) => {
  const navigate = useNavigate();
  const client = generateClient();
  const [view, setView] = useState('details'); 
  const [userDetails, setUserDetails] = useState(null)
  const [tempFirstName, setTempFirstName] = useState("");
  const [tempLastName, setTempLastName] = useState("");
  const [completedModules, setCompletedModules] = useState(null);
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const hideToast = () => {
    setShowToast(false);
  };
  const [passwordVisibility, setPasswordVisibility] = useState({
    old: false,
    new: false,
  });
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const [passwordReq, setPasswordReq] = useState({
    minimum: false,
    uppercase: false,
    lowercase: false,
    numeral: false,
    symbol: false,
  });

  const minimumRegex = new RegExp("^(?=.{8,})");
  const lowerRegex = new RegExp("^(?=.*[a-z])");
  const upperRegex = new RegExp("^(?=.*[A-Z])");
  const numeralRegex = new RegExp("^(?=.*[0-9])");
  const symbolRegex = new RegExp("[^A-Za-z0-9]");

  //1. Query to get user details
  const GET_USERDETAILS_BY_ID_QUERY = `
    query user($id: String) {
      user(id: $id) {
        company {
          id
          name
        }
        defaultCompany {
          id
          name
        }
        id
        email
        firstName
        lastName
        userType
        profilePictureKey
        profilePicture
        businessName
        preferredTimezone
        preferredDateFormat
        isTwoFactorEnabled
        history
        organisations {
          items {
            company {
              id
              name
              representative {
                id
                firstName
                lastName
              }
            }
            userType
            hasPortalAccess
            isDeleted
          }
        }
      }
    }
  `;

  //1. Get user details, store in local storage
  useEffect(() => {
    const getUserById = async (userId) => {
      // const isUser = JSON.parse(localStorage.getItem("userDetails"));

      // if(!isUser){
        try {
          const response = await client.graphql({
            query: GET_USERDETAILS_BY_ID_QUERY,
            variables: {
              id: userId,
            },
          });
          console.log("UserDetails saved in LS: >>", response?.data?.user);
          var userDetailsTemp = response?.data?.user;

          setUserDetails(userDetailsTemp);
          setTempFirstName(userDetailsTemp?.firstName);
          setTempLastName(userDetailsTemp?.lastName);
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      // }else{
      //   console.log("UserDetails already in LS", isUser);
      //   setUserDetails(isUser)
      //   setTempFirstName(isUser?.firstName);
      //   setTempLastName(isUser?.lastName);
      // }
    };

    const fetchUserDetails = async () => {
      if (userDetails === null) {
        const existingUserId = localStorage.getItem("userId");

        if (existingUserId) {
          await getUserById(existingUserId);
        }
      }
    };

    fetchUserDetails();
  }, [userDetails]);

  const handleInputChange = (e, formData) => {
    console.log("Input:", e.target.value)
    if(formData === "firstName"){
      setTempFirstName(e.target.value)
    }else{
      setTempLastName(e.target.value)
    }
  };

  //Saving of updated user details mutation
  const UPDATE_USER_MUTATION = `
    mutation updateUser($email: AWSEmail!, $firstName: String, $id: ID!, $lastName: String, $businessName: String, $preferredTimezone: String, $preferredDateFormat: String, $isTwoFactorEnabled: Boolean, $defaultCompany: CompanyInput, $history: [AWSJSON]) {
      userUpdate(
        firstName: $firstName
        id: $id
        lastName: $lastName
        email: $email
        businessName: $businessName
        preferredTimezone: $preferredTimezone
        preferredDateFormat: $preferredDateFormat
        isTwoFactorEnabled: $isTwoFactorEnabled
        defaultCompany: $defaultCompany
        history: $history
      ) {
        id
      }
    }`;

  //Saving of updated data
  const handleSave = async () => {
    var userInfo = userDetails;
    console.log("To be saved:", userInfo)

      try {
        const request = await client.graphql({
          query: UPDATE_USER_MUTATION,
          variables: {
            firstName: tempFirstName,
            lastName: tempLastName,
            email: userInfo.email,
            id: userInfo.id,
            businessName: userInfo.businessName,
            preferredTimezone: userInfo.preferredTimezone,
            preferredDateFormat: userInfo.preferredDateFormat,
            isTwoFactorEnabled: userInfo.isTwoFactorEnabled,
            defaultCompany: userInfo.defaultCompany,
          },
        }).then(() => {
          localStorage.setItem("firstName", tempFirstName);
          localStorage.setItem("lastName", tempLastName);

          setResultMessage("User details successfully updated.");
          setShowToast(true);
          
          setTimeout(() => {
            setShowToast(false);
            window.location.reload()
          }, 1500);
        });
      } catch (error) {
        console.error(error);
        setResultMessage("There was an error updating your details");
          setShowToast(true);
          
          setTimeout(() => {
            setShowToast(false);
            window.location.reload()
          }, 1500);
      }
  };

  //Password Functions
  function handlePasswordVisibility(event, target) {
    event.preventDefault();
    if (target === "oldPassword") {
      setPasswordVisibility({
        old: !passwordVisibility.old,
        new: passwordVisibility.new,
      });
    }
    if (target === "newPassword") {
      setPasswordVisibility({
        old: passwordVisibility.old,
        new: !passwordVisibility.new,
      });
    }
  }

  function handlePasswordRequirements(event) {
    setPassword(event.target.value);
    let minimum = false;
    let lowercase = false;
    let uppercase = false;
    let numeral = false;
    let symbol = false;

    if (minimumRegex.test(event.target.value)) {
      console.log("MINIMUM TRUE!");
      minimum = true;
    }

    if (lowerRegex.test(event.target.value)) {
      lowercase = true;
    }

    if (upperRegex.test(event.target.value)) {
      uppercase = true;
    }

    if (numeralRegex.test(event.target.value)) {
      numeral = true;
    }

    if (symbolRegex.test(event.target.value)) {
      symbol = true;
    }

    setPasswordReq({
      minimum: minimum,
      lowercase: lowercase,
      uppercase: uppercase,
      numeral: numeral,
      symbol: symbol,
    });
  }

  function handleCheckmark(req, booleanReturn) {
    return req === true ? (
      !booleanReturn ? (
        <MdCheck className="text-secondary w-5 flex-none" />
      ) : (
        true
      )
    ) : !booleanReturn ? (
      <MdOutlineClose className="text-danger w-5 flex-none" />
    ) : (
      false
    );
  }

  //Saving of password
  const handleSavePassword = async (formdata) => {
      console.log("formdata", formdata);
      const { oldPassword, newPassword } = formdata;

      revertToToastDefaults();

      if (oldPassword.trim() === "") {
        setResultMessage("Old password is required.");
        setShowToast(true);
        return false;
      }

      if (newPassword.trim() === "") {
        setResultMessage("New password is required.");
        setShowToast(true);
        return false;
      }

      for (const key in passwordReq) {
        if (!handleCheckmark(passwordReq[key], true)) {
          setResultMessage("Please check the password requirements.");
          setToastError(true);
          setShowToast(true);
          return;
        }
      }

      try {
        //Directly update password
        await updatePassword({
          oldPassword,
          newPassword
        });

        setResultMessage("Your password has been changed.");
        setShowToast(true);
        
        setTimeout(() => {
          setShowToast(false);
          reset({ oldPassword: "", newPassword: "" });
          window.location.reload()
        }, 1500);

      } catch (error) {
        console.log("error", error);
        let msg = error.toString();
        
        // Error message handling
        if (msg.includes("incorrect password")) {
          msg = "Incorrect current password";
        } else if (msg.includes("password required")) {
          msg = "Invalid new password";
        } else if (msg.includes("limit exceeded")) {
          msg = "Attempt limit exceeded, please try again later.";
        }
        
        setResultMessage(msg);
        setToastError(true);
        setShowToast(true);
      }
  };

  const revertToToastDefaults = () => {
    setToastError(false);
    setShowToast(false);
  };

  //Modules Functions
  const GET_COMPLETED_MODULES_QUERY = `
    query getCompletedModuleByUser($userId: String!) {
      completedModules(userId: $userId) {
        modules {
          dateCompleted
          description
          featuredImageUrl
          id
          moduleNumber
          title
          lessons {
            id
            keyConcepts
            learningObjectives
            lessonOverview
            quiz
            title
            video
            knowledgeCheck {
              format
              instructions
              nextSteps
            }
          }
        }
      }
    }
    `;

  //4. Get completed module list
  useEffect(() => {
    const fetchCompletedModules = async () => {
      try {
        const userId = localStorage.getItem("userId");
        console.log("UserID", userId)

        const result = await client.graphql({
          query: GET_COMPLETED_MODULES_QUERY,
          variables: { userId: userId }
        });

        console.log('Completed Module List:', result?.data?.completedModules?.modules);
        
        if(result?.data?.completedModules?.modules){
          const completedModules = result?.data?.completedModules?.modules;
          setCompletedModules(completedModules);
        }else{
          setCompletedModules([]);
        }

        
      } catch (error) {
        console.log("Error fetching modules", error)
      }
    };

    if(completedModules === null){
        fetchCompletedModules(); //Call once
    }
  }, [completedModules]);

  const moduleImages = {
    1: m1,
    2: m2,
    3: m3,
    4: m4,
    5: m5,
    6: m6
  };

  const certificateImages = {
    1: module1,
    2: module2,
    3: module3,
    4: module1, //Will update if available
    5: module1,
    6: module1
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; 
    
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }).replace(',', '');
    } catch (error) {
      console.error('Error formatting date:', error);
      return ''; 
    }
  };

  return (
    <div className="flex flex-col">
    <Navbar user={user} signOut={signOut} />
    <div className="flex min-h-screen mt-20">
      {/* Sidebar */}
      <div className="w-1/4 bg-white border-solid border-r p-6">
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => setView('details')}
              className={`text-left w-full p-2 rounded ${view === 'details' ? 'bg-gray-200' : ''}`}
            >
              My details
            </button>
          </li>
          <li>
            <button
              onClick={() => setView('progress')}
              className={`text-left w-full p-2 rounded ${view === 'progress' ? 'bg-gray-200' : ''}`}
            >
              Academy Progress
            </button>
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="w-3/4 p-2">
        {view === 'details' ? (
          <div className="bg-white p-3 rounded-lg mx-2 mt-4">
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center">
                <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
                  <span className="text-2xl font-bold text-gray-700">{userDetails?.firstName[0]?.toUpperCase()}{userDetails?.lastName[0]?.toUpperCase()}</span>
                </div>
                <div className="ml-4">
                  <h2 className="text-2xl font-bold">{userDetails?.firstName} {userDetails?.lastName}</h2>
                  <p className="text-gray-600">{userDetails?.email}</p>
                </div>
              </div>
              <button
                onClick={handleSave}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>

            {/* Form Fields */}
            <form autoComplete="off" className="border-t border-solid border-gray-300 mt-8">
            <div className="mt-8">
              <div className='flex py-5'>
                <label className="block text-gray-700 w-72">First Name</label>
                <input
                  type="text"
                  defaultValue={tempFirstName}
                  onChange={(e)=>handleInputChange(e, "firstName")}
                  className="-mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  autoComplete="first-name"
                />
              </div>
              <div className='flex py-5'>
                <label className="block text-gray-700 w-72">Last Name</label>
                <input
                  type="text"
                  defaultValue={tempLastName}
                  onChange={(e)=>handleInputChange(e, "lastName")}
                  onClick={()=>console.log("ln",tempLastName )}
                  className="-mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  autoComplete="new-password"
                />
              </div>
              <div className='flex py-5'>
                <label className="block text-gray-700 w-72">Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={userDetails?.email}
                  className="-mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  disabled={true}
                />
              </div>
              <br/>
              <br/>
            </div>
            </form>

            <div className="border-t border-solid border-gray-300 ">
              <form className="grid gap-4 mt-8" onSubmit={handleSubmit(handleSavePassword)}>
            
              <div className="flex flex-col md:grid md:grid-cols-2 md:gap-4">
                <div className="relative flex-auto">
                  <label className="block text-gray-700">Current Password</label>
                  <div className="relative my-2">
                    <input
                      type={passwordVisibility.old ? "text" : "password"}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      placeholder="Current Password"
                      {...register("oldPassword", { required: true })}
                      onChange={(elm) => {
                        if (elm.target.value) {
                          clearErrors("oldPassword");
                        }
                      }}
                      id="currentPassword"
                    />
                    <button
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      type="button"
                      onClick={(e) => handlePasswordVisibility(e, "oldPassword")}
                    >
                      {passwordVisibility.old ? (
                        "Hide"
                      ) : (
                        "Show"
                      )}
                    </button>
                  </div>
                  {errors.oldPassword?.type === "required" && (
                    <div className="error-msg">
                      <AiFillInfoCircle />
                      <p>Current Password is required</p>
                    </div>
                  )}
                </div>

                <div className="relative flex-auto">
                  <p className="input-name">New Password</p>
                  <div className="relative my-2">
                    <input
                      type={passwordVisibility.new ? "text" : "password"}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      placeholder="New Password"
                      {...register("newPassword", { required: true })}
                      onChange={(elm) => {
                        const values = getValues();
                        handlePasswordRequirements(elm);
                        if (values.oldPassword) {
                          clearErrors("newPassword");
                        }
                        if (values.oldPassword && elm.target.value) {
                          if (values.oldPassword === elm.target.value) {
                            setError("comparePassword", {
                              type: "manual",
                              message:
                                "The new password you entered is the same as your current password. Enter a different password.",
                            });
                          } else {
                            clearErrors("comparePassword");
                          }
                        } else {
                          clearErrors("comparePassword");
                        }
                        console.log(errors);
                      }}
                      id="newPassword"
                    />
                    <button
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      type="button"
                      onClick={(e) => handlePasswordVisibility(e, "newPassword")}
                    >
                      {passwordVisibility.new ? (
                        "Hide"
                      ) : (
                        "Show"
                      )}
                    </button>
                  </div>
                  {/* Password Requirements */}
                  <div className="mt-4">
                    <ul className="ml-2">
                      <li className="text-base md:text-md flex items-center gap-2">
                        {handleCheckmark(passwordReq.minimum, false)}
                        Minimum of 8 characters
                      </li>
                      <li className="text-base md:text-md flex items-center gap-2">
                        {handleCheckmark(passwordReq.lowercase, false)}
                        Must include at least 1 lowercase character (a-z)
                      </li>
                      <li className="text-base md:text-md flex items-center gap-2">
                        {handleCheckmark(passwordReq.uppercase, false)}
                        Must include at least 1 uppercase character (A-Z)
                      </li>
                      <li className="text-base md:text-md flex items-center gap-2">
                        {handleCheckmark(passwordReq.numeral, false)}
                        Must include at least 1 numeral (0-9)
                      </li>
                      <li className="text-base md:text-md flex items-center gap-2">
                        {handleCheckmark(passwordReq.symbol, false)}
                        Must include at least 1 symbol
                      </li>
                    </ul>
                  </div>
                  {errors.newPassword?.type === "required" && (
                    <div className="error-msg">
                      <AiFillInfoCircle />
                      <p>New Password is required</p>
                    </div>
                  )}
                  {errors.comparePassword && (
                    <div className="error-msg">
                      <AiFillInfoCircle />
                      {errors.comparePassword.message}
                    </div>
                  )}
                </div>
                <div className="relative flex-auto">
                  {/* confirmPassword */}
                  <p className="input-name mt-4">Confirm New Password</p>
                  <div className="relative my-2">
                    <input
                      id="confirmPassword"
                      type={passwordVisibility.new ? "text" : "password"}
                      placeholder="Confirm New Password"
                      {...register("currentPassword", { required: true })}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      onChange={(elm) => {
                        setPasswordEdit(true);
                        const values = getValues();

                        if (
                          document.getElementById("newPassword").value ===
                          document.getElementById("confirmPassword").value
                        ) {
                          setPasswordEdit(true);
                        }
                        if (values.newPassword) {
                          clearErrors("currentPassword");
                        }
                        if (values.newPassword && elm.target.value) {
                          if (values.newPassword === elm.target.value) {
                            clearErrors("confirmPassword");
                          } else {
                            setError("confirmPassword", {
                              type: "manual",
                              message:
                                "The passwords do not match. Please enter the same password.",
                            });
                          }
                        } else {
                          clearErrors("confirmPassword");
                        }
                        console.log(errors);
                      }}
                    />
                    <button
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      type="button"
                      onClick={(e) => handlePasswordVisibility(e, "newPassword")}
                    >
                      {passwordVisibility.new ? (
                        "Hide"
                      ) : (
                        "Show"
                      )}
                    </button>
                  </div>
                  {errors.confirmPassword && (
                    <div className="error-msg">
                      {errors.confirmPassword.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="grid justify-end">
                <div className="flex justify-end mt-4">
                  <button
                    type="submit"
                    className="bg-white-500 text-black border border-1 px-4 py-2 rounded hover:bg-gray-200"
                  >
                    Change Password
                  </button>
                </div>
              </div>

              {showToast && resultMessage && (
                <ToastNotification
                  title={resultMessage}
                  hideToast={hideToast}
                  error={toastError}
                />
              )}
           
              </form>
            </div> 
          </div>
        ) : (
          <>
          <div className='p-3'>
            <h4 className="text-lg font-semibold">Modules Completed</h4>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {completedModules.map((module, index) => (
                <div key={index} className="rounded-2xl overflow-hidden shadow-lg relative w-80 h-80"> {/* Fixed width */}
                  <div className="relative h-full">
                    {/* Full background image */}
                    <img 
                      src={moduleImages[module.moduleNumber]} 
                      alt={module.title} 
                      className="w-full h-full object-cover absolute inset-0"
                    />
                    
                    <div className="relative p-4">
                      <div className="flex justify-between items-start">
                        <div></div>
                        <img 
                          src={hbaLogo}
                          alt="HBApp Academy" 
                          className="h-8 bg-white p-1 rounded"
                        />
                      </div>
                    </div>

                    {/* Bottom section */}
                    <div className="absolute bottom-0 left-0 right-0 bg-gray-700 bg-opacity-70 backdrop-blur-sm p-4">
                      <h4 className="text-white text-xl font-semibold">
                        Module {module.moduleNumber}: {module.title}
                      </h4>
                      <p className="text-gray-200 mt-1">
                        {formatDate(module?.dateCompleted)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='p-3'>
            <h4 className="text-lg font-semibold">Certificates Earned</h4>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {completedModules.map((module, index) => (
                <div key={index} className="rounded-2xl overflow-hidden shadow-lg relative w-80 h-80"> {/* Fixed width */}
                  <div className="relative h-full">
                    {/* Full background image */}
                    <img 
                      src={certificateImages[module?.moduleNumber]} 
                      alt={module.title} 
                      className="w-full h-full object-cover absolute inset-0"
                    />
                    
                    <div className="relative p-4">
                      <div className="flex justify-between items-start">
                        <div></div>
                        <img 
                          src={hbaLogo}
                          alt="HBApp Academy" 
                          className="h-8 bg-white p-1 rounded"
                        />
                      </div>
                    </div>

                    {/* Bottom section */}
                    <div className="absolute bottom-0 left-0 right-0 bg-gray-700 bg-opacity-70 backdrop-blur-sm p-4">
                      <h4 className="text-white text-xl font-semibold">
                        Module {module.moduleNumber}: {module.title}
                      </h4>
                      <p className="text-gray-200 mt-1">
                        {formatDate(module?.dateCompleted)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* View Academy Dashboard Button */}
          <div className="flex justify-center mt-8 pb-8">
            <button 
              className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-colors"
              onClick={() => navigate('/dashboard')}
            >
              View Academy Dashboard
            </button>
          </div>
          </>
        )}
      </div>
    </div>
   
    </div>
  );
}

export default Profile;
