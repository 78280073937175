import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Banner = () => {
  const moduleNum  = useParams().ModuleNum;

  console.log(moduleNum);
  

  const courseList = ["Home Building 101", "Understanding Building Regulations", "Budget Management Masterclass"]

  return (
    <div className="bg-white p-3 fixed w-full top-20 border-t border-b border-gray-200"> {/* Add border-t for top border */}
      <h1 className="text-2xl font-semibold text-gray-900 ml-4">
        {courseList[Number(moduleNum)-1]}    
      </h1>
      <p className="text-sm text-gray-500 ml-4">Welcome to {courseList[Number(moduleNum)-1]}   Certification</p>
    </div>
  );
};

export default Banner;
